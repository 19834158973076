<template>
  <div class="c-app flex-row align-items-center">
    <b-container>
      <b-row class="justify-content-center">
        <b-col md="6">
          <b-card class="p-4">
            <b-card-body>
              <b-form @submit.prevent="onLogin">
                <h1>
                  <div class="brand"></div>
                  Z! Hub - Login
                </h1>
                <p class="text-muted">Sign In to your account</p>
                <b-alert variant="danger" v-model="showUserError">{{
                  userError
                }}</b-alert>
                <b-input-group class="mb-2">
                  <b-input-group-prepend is-text>
                    <b-icon icon="person-fill"></b-icon>
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="username"
                    id="username"
                    placeholder="Username"
                    autocomplete="username"
                    autocorrect="off"
                    autocapitalize="none"
                    required="required"
                  >
                  </b-form-input>
                </b-input-group>
                <b-input-group class="mb-2">
                  <b-input-group-prepend is-text>
                    <b-icon icon="key-fill"></b-icon>
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="password"
                    id="password"
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                    required="required"
                  >
                    <template #prepend-content><b-icon icon="camera" /></template>
                  </b-form-input>
                </b-input-group>
                <b-row>
                  <b-col cols="6" class="text-left">
                    <b-button
                      id="login"
                      variant="success"
                      type="submit"
                      class="px-4"
                      :disabled="working"
                      >Login</b-button
                    >
                  </b-col>
                  <b-col cols="6" class="text-right">
                    <b-button
                      variant="link"
                      class="px-0"
                      :to="{ name: 'PasswordReset' }"
                      :disabled="working"
                      >Forget your password?</b-button
                    >
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Login',
  data() {
    return {
      working: false,
      username: null,
      password: null,
    }
  },
  computed: {
    ...mapGetters(['userError', 'isAuthenticated']),
    showUserError() {
      return this.userError !== null
    },
  },
  methods: {
    ...mapActions(['login']),
    async onLogin() {
      this.working = true
      await this.login({
        username: this.username,
        password: this.password,
      })
      this.working = false
      if (this.isAuthenticated) {
        const iniView = 'SscBooking' // @TODO get from preferences
        this.$router.push({ name: iniView })
        // @TODO make this.$auth the standard opposed to this.getters.currentUser?
        this.$auth.user = { ...this.$store.state.currentUser.currentUser }
      }
    },
  },
}
</script>

<style scoped>
.card {
  border-radius: 2rem;
}
.brand {
  width: 34px;
  height: 34px;
  display: inline-block;
  background-color: #fff;
  background-image: url(../../assets/img/logo-symbol.png);
  background-size: 30px;
  background-position: 0 4px;
  background-repeat: no-repeat;
}
</style>
